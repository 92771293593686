/* Third-Party */
import jQuery from 'jquery';
import "bootstrap";
import owlCarousel from "owl.carousel";
//import '@fancyapps/fancybox';

/* Layout-Parts */
import { lazyload } from './globals/lazyload';
import { header } from './layout/header';

/* Blocks */
import { teaserslider } from '../../blocks/teaserslider/script';
import { reviews } from '../../blocks/bewertungen/script';
import { team } from '../../blocks/ansprechpartner/script';
import { teaserliste } from '../../blocks/teaserliste/script';

jQuery(document).ready(_ => {
    lazyload();
    header();
    teaserslider();
    reviews();
    team();
    team();
    teaserliste();
});